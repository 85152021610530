@charset "utf-8";

.footer_site {
    width: 100%;
    padding: 56px 0 105px 0;
    background-color: black;
    color: white;
    position: relative;
    left: 0;
    bottom: 0;

    .content_pd {
        padding: 0 25px;

        .warpper {
            width: 100%;
            margin: 0 auto 0;
        }
    }
}

.ul_links {
    padding: 0;
    margin: 0;
    list-style: none;
    li {
        padding: 0 0 10px 0;

        &:last-child {
            padding: 0;
        }
        
        a {
            color: white;
            text-decoration: none;
            font-size: 16px;
            line-height: 1.3;
            text-transform: uppercase;
            position: relative;
            i {
                -ms-filter: "progid:DXImageTransform.Microsoft.BasicImage(rotation=3)";
                transform: rotate(45deg);
                position: relative;
                top: 1px;
                margin: 0 3px 0 0;
                -webkit-transition: all 0.2s;
                -moz-transition: all 0.2s;
                -o-transition: all 0.2s;
                transition: all 0.2s;
            }

            &:hover {
                color: #ffdb25;
                i {
                    transform: rotate(95deg);
                }
            }
        }
    }
}

.ul_rs {
    padding: 0;
    margin: 0 0 10px 0;
    list-style: none;
    li {
        display: inline-block;
        margin: 0 8px 0 0;
        a {
            display: block;
            width: 32px;
            height: 32px;
            background-color: white;
            border-radius: 50%;
            color: black;
            text-decoration: none;
            font-size: 20px;
            line-height: 36px;
            text-align: center;

            -webkit-transition: all 0.2s;
            -moz-transition: all 0.2s;
            -o-transition: all 0.2s;
            transition: all 0.2s;

            &:hover {
                background-color: #ffdb25;
            }

            &.spotify_btn {
                background-color: transparent;
                color: white;
                font-size: 32px;
                position: relative;
                top: 4px;

                &:hover {
                    color: #ffdb25;
                }
            }
        }
    }
}

.ultcol {
    // text-align: right;
    p {
        font-size: 18px;
        text-transform: uppercase;
        margin: 0 0 10px 0;
    }

    input {
        display: block;
        width: 75%;
        height: 52px;
        line-height: 50px;
        float: left;
        border: 1px solid white;
        background-color: black;
        font-size: 18px;
        color: white;
        padding: 0 20px;
        &::placeholder {
            text-transform: uppercase;
            color: #747474;
        }
        &:focus {
            outline: none;
            box-shadow: none;
        }
    }

    button {
        display: block;
        width: 25%;
        height: 52px;
        line-height: 52px;
        float: left;
        border: 0;
        background-color: white;
        color: black;
        padding: 0;
        text-align: center;
        font-size: 18px;
        &:hover {
            color: black;
            text-decoration: none;
            background-color: #ffdb25;
            border-color: #ffdb25;
        }

        &:focus {
            box-shadow: none;
        }
    }
}

@media (max-width:1023px) {
    .ul_links {
        padding: 0 0 25px 0;
        li {
            a {
                font-size: 15px;
            }
        }
    }
    .ul_rs {
        margin: 0 0 32px 0;
    }
    .ultcol {
        p {
            font-size: 15px;
        }
        input {
            font-size: 14px;
            padding: 0 10px;
            appearance: none;
            border-radius: 0;
        }
        button {
            font-size: 14px;
            appearance: none;
            border-radius: 0;
        }
    }
    .content_pd {
        padding: 0 !important;
    }
    .footer_site {
        padding: 40px 0 65px 0;
    }
}

@media (max-width:767px) {
    .ul_links {
        li {
            a {
                font-size: 14px;
            }
        }
    }

    .ultcol {
        p {
            font-size: 14px;
        }
        input {
            font-size: 14px;
            height: 42px;
            line-height: 40px;
        }
        button {
            font-size: 14px;
            height: 42px;
            line-height: 42px;
        }
    }
}