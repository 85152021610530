@charset "utf-8";
.content_dt {
    background-color: white;
    color: black;
    min-height: 45vh;
    padding: 0;

    .content_dtfilmes {
        padding: 60px 25px;

        .wrapper {
            width: 100%;
            margin: 0 auto 0;

            h1 {
                font-size: 30px;
                font-weight: bold;
                line-height: 1.2;
                padding: 0;
                margin: 0 0 35px 0;
            }

            p {
                font-size: 20px;
                line-height: 1.2;
                font-weight: 500;
                letter-spacing: 0.01rem;
                padding: 0;
                margin: 0 0 20px 0;
            }
        }
    }
}

.box_politica {
    div {
        column-count: 3;
    }
}

@media (max-width:1024px) {
    .box_politica {
        div {
            column-count: 2;
        }
    }
    .content_dt {
        .content_dtfilmes {

            .wrapper {

                h1 {
                    font-size: 26px;
                    margin: 0 0 25px 0;
                }

                p {
                    font-size: 16px;
                }
            }
        }
    }
}

@media (max-width:1023px) {
    .content_dt {
        .content_dtfilmes {
            padding: 35px 0;

            .wrapper {

                h1 {
                    font-size: 26px;
                    margin: 0 0 25px 0;
                }

                p {
                    font-size: 16px;
                }
            }
        }
    }
}

@media (max-width:767px) {
    .box_politica {
        div {
            column-count: 1;
        }
    }
    .content_dt {
        .content_dtfilmes {
            padding: 35px 0;

            .wrapper {

                h1 {
                    font-size: 20px;
                    margin: 0 0 25px 0;
                }

                p {
                    font-size: 14px;
                    margin-bottom: 15px;
                }
            }
        }
    }
}