.App {
  text-align: center;
}

.App-logo {
  animation: App-logo-spin infinite 20s linear;
  height: 40vmin;
  pointer-events: none;
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.page-sobre header {
  position: absolute;
  background: -moz-linear-gradient(top, rgba(0,0,0,1) 41%, rgba(0,0,0,0.17) 90%, rgba(0,0,0,0) 100%);
  background: -webkit-linear-gradient(top, rgba(0,0,0,1) 41%,rgba(0,0,0,0.17) 90%,rgba(0,0,0,0) 100%);
  background: linear-gradient(to bottom, rgba(0,0,0,1) 41%,rgba(0,0,0,0.17) 90%,rgba(0,0,0,0) 100%);
  filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#000000', endColorstr='#00000000',GradientType=0 );
}