@charset "utf-8";
.content_dt {
    background-color: white;
    color: black;
    min-height: 45vh;
    padding: 0;

    .content_dtfilmes {
        padding: 60px 25px;

        .wrapper {
            width: 100%;
            margin: 0 auto 0;
        }
    }
}

.img_blog {
    margin: 0;
    padding: 0;
    img {
        padding: 0;
        margin: 0;
    }
}


.date_post {
    font-size: 18px;
    line-height: 18px;
    display: inline-block;
    text-transform: uppercase;
    letter-spacing: 0.05rem;
    margin: 0;
}

.title_post {
    font-size: 52px;
    font-weight: bold;
    display: inline;
    padding: 0;
    margin: 0;
    white-space: pre-wrap;
    border: 0 solid #ffdb25;
    border-width: 0.25em 0;
    position: relative;
    background-color: #ffdb25;
    left: 15px;

    &::after {
        content: "";
        position: absolute;
        top: -0.25em;
        right: 100%;
        bottom: -0.25em;
        width: 0.25em;
        background-color: #ffdb25;
    }

    span {
        position: relative;
        z-index: 1;
        letter-spacing: 0;
    }
}

.description_post {
    font-size: 30px;
    font-weight: bold;
    line-height: 1.1;
    padding: 30px 0 20px 0;
    margin: 0;
}

.autor_post {
    font-size: 18px;
    line-height: 18px;
    display: block;
    text-transform: uppercase;
    letter-spacing: 0.05rem;
    padding: 0;
    position: relative;
}

.post_content {
    padding-top: 30px;

    p {
        font-size: 26px;
        font-weight: 500;
        line-height: 1.2;
        margin-bottom: 26px;

        strong, b {
            font-weight: bold;
        }
    }
}

.mabottom {
    margin-bottom: 20px;
}

.listshared {
    list-style: none;
    padding: 0;
    margin: 0;
    display: inline-block;

    li {
        display: inline-block;
        padding: 0 0 0 15px;
        
        a {
            -webkit-transition: all 0.5s;
            -moz-transition: all 0.5s;
            -o-transition: all 0.5s;
            transition: all 0.5s;

            &:hover {
                -webkit-transition: all 0.5s;
                -moz-transition: all 0.5s;
                -o-transition: all 0.5s;
                transition: all 0.5s;
            }
        }

        .email {
            font-size: 30px;
            color: black;
            position: relative;
            top: 4px;
            &:hover {
                color: #ffdb25; 
            }
        }
        .faceb {
            width: 32px;
            height: 32px;
            line-height: 36px;
            display: inline-block;
            font-size: 20px;
            color: white;
            text-align: center;
            position: relative;
            top: -2px;
            background-color: black;
            border-radius: 50%;
            &:hover {
                color: black;
                background-color: #ffdb25;
            }
        }
        .twitter {
            width: 32px;
            height: 32px;
            line-height: 36px;
            display: inline-block;
            font-size: 20px;
            color: white;
            text-align: center;
            position: relative;
            top: -2px;
            background-color: black;
            border-radius: 50%;
            &:hover {
                color: black;
                background-color: #ffdb25;
            }
        }
    }
}

.fonte {
    position: relative;
    padding: 30px 0 0 0;
    margin: 60px 0 0 0;
    font-size: 18px;

    a {
        color: black;
    }

    &::before {
        content: '';
        width: 80px;
        height: 5px;
        background-color: #ffdb25;
        position: absolute;
        top: 0;
        left: 0;
    }
}

@media (max-width:1920px) {
    .date_post {
        font-size: 18px;
        line-height: 18px;
    }
    .title_post {
        font-size: 48px;
        line-height: 1.15;
    }
    .description_post {
        font-size: 28px;
        line-height: 36px;
    }
    .autor_post {
        font-size: 18px;
        line-height: 18px;
    }
}

@media (max-width:1600px) {
    .date_post {
        font-size: 15px;
        line-height: 15px;
        margin: 5px 0 25px 0;
    }
    .autor_post {
        font-size: 15px;
        line-height: 15px;
    }
    .title_post {
        font-size: 40px;
        left: 10px;
    }
    .description_post {
        font-size: 25px;
        line-height: 1.1;
    }
}

@media (max-width:1280px) {
    .content_dt {
        .content_dtfilmes {
            padding: 60px 35px 0 35px;
        }
    }
    .title_post {
        font-size: 30px;
        left: 7px;
    }
    .description_post {
        font-size: 20px;
    }
    .date_post {
        font-size: 14px;
        line-height: 14px;
        margin: 5px 0 0 0;
    }
    .autor_post {
        font-size: 14px;
        line-height: 14px;
    }
    .post_content {
        p {
            font-size: 22px;
            margin-bottom: 20px;
        }
    }
}

@media (max-width:1023px) {
    .title_post {
        font-size: 26px;
    }
    
    .content_dtfilmes {
        padding: 40px 0 0 0 !important;
    }
    .description_post {
        font-size: 16px;
    }
    .post_content {
        p {
            font-size: 20px;
            line-height: 24px;
        }
    }
    .fonte {
        margin: 15px 0 30px 0;
        padding: 15px 0 30px 0;
    }
}

@media (max-width:900px) {
    .title_post {
        font-size: 20px;
        left: 0;
    }
     .post_content {
        p {
            font-size: 16px;
            line-height: 20px;
        }
    }
    .box_card {
        .col_um {
            width: 100%;
            height: auto;
            .img_desktop {
                display: none;
            }
            .o_hidden_img {
                display: block;
            }
        }
        .col_dois {
            width: 100%;
            height: auto;
            p {
                font-size: 16px;
            }
        }
    }
    .autor_post {
        &::after {
            width: 80px;
            height: 3px;
        }
    }
    .fonte {
        font-size: 16px;
    }
}

@media (max-width:767px) {
    .title_post {
        font-size: 18px;
    }
    .description_post {
        font-size: 14px;
        padding: 20px 0 10px 0;
    }
    .autor_post {
        padding: 10px 0 20px 0 !important;
    }
    .post_content {
        p {
            font-size: 14px;
            line-height: 18px;
            margin-bottom: 15px;
        }
    }
    .fonte {
        font-size: 14px;
    }
    .date_post {
        font-size: 13px;
    }
    .listshared {
        li {
            padding: 0 0 0 9px;
            .email {
                font-size: 25px;
            }
            .faceb, .twitter {
                width: 24px;
                height: 24px;
                line-height: 24px;
                display: inline-block;
                font-size: 14px;
            }
        }
    }
    .box_card {
        padding-bottom: 35px;
        margin-bottom: 35px;
        .col_dois {
            p {
                font-size: 14px;
                margin: 15px 0 0 0;
            }
        }
    }
    .date_post {
        margin: 10px 0 15px 0;
    }
    .autor_post {
        padding: 20px 0;
    }
    .btn_black {
        height: 40px;
        line-height: 40px;
    }
}