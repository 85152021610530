@charset "utf-8";
.content_dt {
    background-color: white;
    color: black;
    min-height: 45vh;
    padding: 0;
    letter-spacing: 0.06rem;

    .content_dtfilmes {
        padding: 90px 35px 0 35px;

        .wrapper {
            width: 100%;
            margin: 0 auto 0;

            img {
                width: 100%;
            }

            .box_card {
                &:nth-child(2n+1) {
                    .col_um {
                        float: left;
                    }
                    .col_dois {
                        float: left;
                    }
                }

                &:last-child {
                    border-bottom: 0;
                }
            }
        }
    }

    .box_card {
        color: black;
        text-decoration: none;
    }
}

.box_card {
    display: block;
    border-bottom: 1px solid black;
    padding-bottom: 55px;
    margin-bottom: 55px;

    .col_um {
        width: 66%;
        height: 100%;
        float: right;
        padding: 0 15px;
    }

    .col_dois {
        width: 33%;
        float: left;
        padding: 0 15px;
    }

    &:hover {
        figure {
            img {
                -webkit-transform: scale(1.1);
                -ms-transform: scale(1.1);
                transform: scale(1.1);

                -webkit-transition: all 5s ease-in-out;
                -moz-transition: all 5s ease-in-out;
                -o-transition: all 5s ease-in-out;
                transition: all 5s ease-in-out;
            }
        }
        .img_desktop {
            .imgbox {
                -webkit-transform: scale(1.1);
                -ms-transform: scale(1.1);
                transform: scale(1.1);

                -webkit-transition: all 5s ease-in-out;
                -moz-transition: all 5s ease-in-out;
                -o-transition: all 5s ease-in-out;
                transition: all 5s ease-in-out;
            }
        }
    }
}

.img_desktop {
    position: relative;
    width: 100%;
    height: 100%;
    overflow: hidden;
    .imgbox {
        position: relative;
        width: 100%;
        height: 100%;
        background-size: cover;
        background-position: center;

        -webkit-transition: all 0.9s ease-in-out;
        -moz-transition: all 0.9s ease-in-out;
        -o-transition: all 0.9s ease-in-out;
        transition: all 0.9s ease-in-out;
    }
}

.o_hidden_img {
    display: none;
    overflow: hidden;
    padding: 0;
    margin: 0;
    position: relative;
    img {
        margin: 0;
        position: relative;

        -webkit-transition: all 0.9s ease-in-out;
        -moz-transition: all 0.9s ease-in-out;
        -o-transition: all 0.9s ease-in-out;
        transition: all 0.9s ease-in-out;
    }
}

.date_post {
    font-size: 18px;
    line-height: 18px;
    display: block;
    text-transform: uppercase;
    letter-spacing: 0.05rem;
    margin: 5px 0 44px 0;
}

.title_post {
    font-size: 52px;
    font-weight: bold;
    display: inline;
    padding: 0;
    margin: 0;
    white-space: pre-wrap;
    border: 0 solid #ffdb25;
    border-width: 0.25em 0;
    position: relative;
    background-color: #ffdb25;
    left: 15px;

    &::after {
        content: "";
        position: absolute;
        top: -0.25em;
        right: 100%;
        bottom: -0.25em;
        width: 0.25em;
        background-color: #ffdb25;
    }

    span {
        position: relative;
        z-index: 1;
        letter-spacing: 0;
    }
}

.description_post {
    font-size: 30px;
    font-weight: bold;
    line-height: 1.1;
    padding: 0;
    margin: 40px 0 0 0;
}

.autor_post {
    font-size: 18px;
    line-height: 18px;
    display: block;
    text-transform: uppercase;
    letter-spacing: 0.05rem;
    padding: 55px 0;
    margin-bottom: 55px;
    position: relative;

    &::after {
        content: '';
        width: 90px;
        height: 7px;
        background-color: #ffdb25;
        position: absolute;
        left: 0;
        bottom: 0;
    }
}

.btn_black {
    position: relative;
    display: inline-block;
    background-color: black;
    border-radius: 0;
    height: 65px;
    line-height: 65px;
    padding: 0 60px 0 50px;
    font-size: 18px;
    text-transform: uppercase;
    color: white;

    span {
        display: inline-block;
        padding: 0 0 0 10px;
        position: absolute;
        font-size: 25px;
        top: 2px;
        right: 35px;
    }

    &:hover {
        color: white;
        text-decoration: none;
        background-color: #484242;
    }
}

@media (max-width:1920px) {
    .date_post {
        font-size: 18px;
        line-height: 18px;
    }
    .title_post {
        font-size: 48px;
        line-height: 1.15;
    }
    .description_post {
        font-size: 28px;
        line-height: 36px;
    }
    .autor_post {
        font-size: 18px;
        line-height: 18px;
        padding: 45px 0;
        margin-bottom: 45px;
    }
    .btn_black {
        font-size: 18px;
        height: 58px;
        line-height: 58px;
    }
}

@media (max-width:1600px) {
    .date_post {
        font-size: 15px;
        line-height: 15px;
        margin: 5px 0 25px 0;
    }
    .autor_post {
        font-size: 15px;
        line-height: 15px;
        padding: 30px 0;
        margin-bottom: 30px;
        &::after {
            height: 4px;
        }
    }
    .title_post {
        font-size: 40px;
        left: 10px;
    }
    .description_post {
        font-size: 25px;
        line-height: 1.1;
        margin: 25px 0 0 0;
    }
    .btn_black {
        font-size: 15px;
        height: 50px;
        line-height: 50px;
    }
}

@media (max-width:1280px) {
    .content_dt {
        .content_dtfilmes {
            padding: 60px 35px 0 35px;
        }
    }
    .title_post {
        font-size: 30px;
        left: 7px;
    }
    .description_post {
        font-size: 20px;
    }
    .date_post {
        font-size: 14px;
        line-height: 14px;
    }
    .autor_post {
        font-size: 14px;
        line-height: 14px;
    }
    .btn_black {
        font-size: 14px;
    }
}

@media (max-width:1023px) {
    
    .content_dtfilmes {
        padding: 40px 0 0 0 !important;
    }
    .box_card {
        .col_dois {
            h2 {
                font-size: 26px;
                letter-spacing: 0.06rem;
            }
        }
    }
    .btn_black {
        padding: 0 40px 0 20px;
        height: 40px;
        line-height: 40px;
        span {
            font-size: 22px;
            top: 1px;
            right: 18px;
        }
    }
    .description_post {
        letter-spacing: 0.06rem;
    }
}

@media (max-width:900px) {
    .box_card {
        .col_um {
            width: 100%;
            height: auto;
            .img_desktop {
                display: none;
            }
            .o_hidden_img {
                display: block;
            }
        }
        .col_dois {
            width: 100%;
            height: auto;
            h2 {
                font-size: 20px;
            }
            p {
                font-size: 16px;
            }
        }
    }
    .autor_post {
        &::after {
            width: 80px;
            height: 3px;
        }
    }
}

@media (max-width:767px) {
    .box_card {
        padding-bottom: 35px;
        margin-bottom: 35px;
        .col_dois {
            h2 {
                font-size: 18px;
                left: 0;
            }
            p {
                font-size: 14px;
                margin: 15px 0 0 0;
            }
        }
    }
    .date_post {
        margin: 10px 0 15px 0;
    }
    .autor_post {
        padding: 20px 0;
    }
    .btn_black {
        height: 40px;
        line-height: 40px;
    }
}