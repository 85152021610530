@charset "utf-8";

.mercado_dt {
    font-weight: 500;
    line-height: 1.3;
    font-size: 26px;

    strong {
        font-weight: bolder;
    }

    @media (max-width: 767px) {
        font-size: 18px;
    }

    @media (max-width: 1023px) {
        margin-top: 50px;
    }
}

.btn_black {
    height: 60px;
    line-height: 60px;
    padding: 0 40px !important;
    background-color: #000;
    color: #fff;
    text-transform: uppercase !important;
    font-size: 18px;
    font-weight: 400 !important;
    text-decoration: none !important;
    margin-top: 50px !important;
    display: inline-block !important;
    position: relative !important;

    @media (max-width: 1023px) {
        height: 40px !important;
        line-height: 40px !important;

        &:hover {
            background-color: #ffc107;
            color: #000;
        }
    }

    @media (max-width:1280px) {
        font-size: 14px !important;

        &:hover {
            background-color: #ffc107;
            color: #000;
        }
    }

    @media (max-width:1600px) {
        font-size: 15px;
        height: 50px;
        line-height: 50px;

        &:hover {
            background-color: #ffc107;
            color: #000;
        }
    }
}

.disc_li {
    ul {
        padding-left: 20px !important;
        list-style: disc !important;
    }

    li {
        font-size: 26px;
        font-weight: 500 !important;
        line-height: 1.6 !important;

        @media (max-width: 800px) {
            font-size: 15px !important;
        }

        @media (max-width:1366px) {
            font-size: 1.2rem;
        }
    }
}

.content_pd {
    padding: 0 30px;
}

.noth1 {
    font-size: 0 !important;
    color: transparent !important;
    margin-bottom: 0 !important;
}

.content {
    width: 100%;
    min-height: 40vh;
    background-color: white;
    color: black;

    h3 {
        font-size: 26px;
        font-weight: 500;
        line-height: 1.3;
    }

    p {
        font-size: 26px;
        font-weight: 500;
        line-height: 1.3;

        strong {
            font-weight: bold;
        }

        em {
            display: inline;
            padding: 0;
            margin: 0;
            white-space: pre-wrap;
            border: 0 solid #ffdb25;
            border-width: 0.25em 0;
            position: relative;
            background-color: #ffdb25;
            left: 0;
            font-style: normal;
        }
    }

    ul {
        list-style: none;
        padding: 0;
        margin: 0;

        li {
            padding: 0 !important;

            a {
                font-weight: bold;
                font-size: 26px;
                color: black;
                text-decoration: none;
                position: relative;

                &:hover {
                    &::after {
                        content: '';
                        width: 100%;
                        height: 1px;
                        background-color: black;
                        position: absolute;
                        left: 0;
                        bottom: 3px;
                    }
                }
            }
        }
    }

    @media (max-width:1366px) {

        h3,
        p,
        ul li a {
            font-size: 22px;
        }
    }
}


.destexto {
    display: inline-block;
    background-color: white;
    padding: 15px 22px 10px;
    margin: 0 0 65px;
    font-weight: 700;
    font-size: 80px;
    line-height: 93px;
    color: black;
    position: relative;

    &::before {
        content: '';
        width: 100px;
        height: 5px;
        background-color: #ffdb25;
        position: absolute;
        top: -27px;
        left: 0;
    }

    h2 {
        font-size: 55px;
        font-weight: 700;
        padding: 0;
        margin: 0;
    }
}

.content_dt {
    width: 100%;
    margin: 0 auto;
}

.content_dt_2 {
    width: 100%;
    margin: 0 auto;
    padding-top: 50px;
    padding-bottom: 100px;
}

.destaque {
    width: 100%;
    height: auto;
    position: relative;
    background-size: cover;
    background-position: center;

    @media (max-width:1600px) {
        height: 580px;
    }

    @media (max-width:1440px) {
        height: 500px;
    }

    @media (max-width:1366px) {
        height: 430px;
    }

    @media (max-width:1280) {
        height: 370px;
    }

    @media (max-width: 800px) {
        height: 300px;
    }

    @media (max-width: 767px) {
        height: 230px;
    }
}

.divisor {
    width: 100px;
    height: 7px;
    background-color: #ffdb25;
    display: block;
    margin: 35px 0 35px 0;
}

@media (max-width:1600px) {
    .destexto {
        line-height: 40px;

        h2 {
            font-size: 40px;
        }
    }
}

@media (max-width:1440px) {
    .destexto {
        line-height: 35px;
        margin: 0 0 35px;

        h2 {
            font-size: 35px;
        }
    }
}

@media (max-width:1023px) {
    .content_pd {
        padding: 0;
    }

    .destexto {
        margin: 0 0 15px 0;
        padding: 9px 15px 8px 15px;
        line-height: 40px;

        &::before {
            width: 80px;
            height: 3px;
            top: -15px;
        }

        h2 {
            font-size: 28px;
        }
    }

    .content {
        p {
            font-size: 20px;
        }

        ul {
            li {
                a {
                    font-size: 20px;
                }
            }
        }
    }

    .content_dt_2 {
        div {
            display: flex;
            flex-direction: column;
            max-width: 100% !important;

            h3 {
                margin-top: 50px;
            }

            a {
                max-width: 133px !important;
            }
        }
    }

    .divisor {
        width: 80px;
        height: 3px;
    }
}

@media (max-width:800px) {
    .content {
        p {
            font-size: 16px;
        }

        ul {
            li {
                a {
                    font-size: 16px;
                }
            }
        }
    }
}

@media (max-width:767px) {
    .destexto {
        line-height: 32px;
        padding: 0;
        margin: 31px 0 0;
    }

    .content_dt_2 {
        padding-top: 30px;
        padding-bottom: 50px;
    }

    .destaque {
        h2 {
            font-size: 20px;
        }
    }

    .content {
        p {
            font-size: 14px;
        }

        ul {
            li {
                a {
                    font-size: 14px;
                }
            }
        }
    }
}