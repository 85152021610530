@charset "utf-8";

.content_dt {
    background-color: white;
    color: black;
    min-height: 45vh;
    padding: 0;

    .noth1 {
        font-size: 0;
        color: transparent;
        padding: 0;
        margin: 0;
    }

    .content_dtfilmes {
        padding: 0 25px;

        .wrapper {
            max-width: 1200px;
            width: 100%;
            margin: 0 auto 0;
            padding-top: 100px;
            padding-bottom: 100px;

            input {
                width: 100%;
                height: 62px;
                border: 1px solid black;
                border-radius: 0;
                padding: 0 20px;
                font-size: 18px;
                color: black;

                &::placeholder {
                    font-size: 18px;
                    color: black;
                }

                &:focus {
                    box-shadow: none;
                }
            }

            textarea {
                width: 100%;
                height: 400px;
                border: 1px solid black;
                border-radius: 0;
                padding: 20px;
                font-size: 18px;
                color: black;

                &::placeholder {
                    font-size: 18px;
                    color: black;
                }

                &:focus {
                    box-shadow: none;
                }
            }

            button {
                background-color: black !important;
                border: 1px solid black;
                border-radius: 0;
                height: 65px;
                line-height: 65px;
                padding: 0 77px;
                font-size: 18px;

                &:hover {
                    color: black;
                    text-decoration: none;
                    background-color: #ffdb25 !important;
                    border-color: #ffdb25 !important;
                }

                &:focus {
                    box-shadow: none;
                }
            }

            /* div {
                margin-top: 0;
            }

            h2 {
                margin-bottom: 20px;
            }

            div {
                h3 {
                    margin-top: 20px;
                }

                p {
                    font-size: 20px;
                    margin: 5px;
                }
            } */

        }
    }
}

.dados {
    padding: 30px 0 0 0;

    h2 {
        font-size: 26px;
        font-weight: bold;
    }

    h3 {
        font-size: 26px;
    }

    span {
        display: block;
    }

    p {
        font-size: 20px;
        font-weight: 500;
        line-height: 1.2;
        position: relative;
        padding: 0 0 30px 0;
        margin: 0;

        &::after {
            content: '';
            width: 80px;
            height: 5px;
            background-color: #ffdb25;
            position: absolute;
            left: 0;
            bottom: 0;
        }
    }
}


@media (max-width:1366px) {
    .content_dt {
        .content_dtfilmes {
            .wrapper {
                padding-top: 60px;
                padding-bottom: 60px;

                textarea {
                    height: 330px;
                }
            }
        }
    }
}

@media (max-width:1023px) {
    .content_dt {
        .content_dtfilmes {
            padding: 0;

            .wrapper {
                padding-top: 35px;
                padding-bottom: 35px;

                input {
                    height: 52px;
                    font-size: 16px;
                    margin-bottom: 33px;

                    &::placeholder {
                        font-size: 16px;
                    }
                }

                textarea {
                    height: 260px;
                    font-size: 16px;
                    margin-bottom: 33px;

                    &::placeholder {
                        font-size: 16px;
                    }
                }

                button {
                    font-size: 16px;
                    height: 52px;
                    line-height: 52px;
                    padding: 0 50px;
                }

                h1 {
                    font-size: 30px;
                }

                h2 {
                    font-size: 25px;
                }

                p {
                    font-size: 20px;
                }
            }
        }
    }

    .dados {
        padding: 16px 0 0 0;
    }
}

@media (max-width:767px) {
    .content_dt {
        .content_dtfilmes {
            .wrapper {
                input {
                    height: 42px;
                    font-size: 14px;

                    &::placeholder {
                        font-size: 14px;
                    }
                }

                textarea {
                    height: 125px;
                    font-size: 14px;

                    &::placeholder {
                        font-size: 14px;
                    }
                }

                button {
                    font-size: 14px;
                    height: 42px;
                    line-height: 42px;
                }

                h1 {
                    font-size: 25px;
                }

                h2 {
                    font-size: 20px;
                }

                p {
                    font-size: 15px;
                }
            }
        }
    }

    .dados {
        h2 {
            font-size: 20px;
        }

        h3 {
            font-size: 20px;
        }

        p {
            font-size: 14px;
        }
        a{
            display: block;
        }
    }
}