ol {
    margin-right: 40px !important;
    margin-left: 40px !important;
    justify-content: flex-end !important;
    
}

ol li {
    width: 16px !important;
    height: 16px !important;
    border-radius: 50% !important;
    border: 1px solid white !important;
    background-color: transparent !important;
    opacity: 1 !important;
    margin: 0 8px !important;
}

ol li:last-child {
    margin: 0 0 0 8px !important;
}

ol li.active {
    background-color: white !important;
}

.carousel-control-prev, .carousel-control-next {
    width: 5%;
}

.carousel-indicators {
    bottom: 30px;
}

.carousel.disable .carousel-control-prev, .carousel.disable .carousel-control-next, .carousel.disable .carousel-indicators {
    display: none;
}