@charset "utf-8";

.content_dt {
    background-color: white;
    color: black;
    padding: 0;
    margin: 0;

    .imgdestaque {
        width: 100%;
        height: auto;
    }

    .content_dtfilmes {
        width: 100%;
        margin: 0 auto 0;
        padding: 0;
    }
}

.header_content {
    padding: 25px 25px 0 25px;

    h1 {
        font-size: 70px;
        font-weight: bold;
        padding: 0;
        margin: 0;
    }

    .box_descri {
        border-bottom: 1px solid black;
        padding: 50px 0 5px 0;
        margin: 0 0 50px 0;
    }

    .title_dt {
        font-size: 18px;
        font-weight: normal;
        color: black;
        text-transform: uppercase;
        display: block;
        margin-bottom: 5px;
    }

    .name_dt {
        font-size: 26px;
        font-weight: bold;
        color: black;
        display: block;
        margin-bottom: 30px;
        line-height: 30px;
    }

    .ano_dt {
        font-size: 26px;
        font-weight: bold;
        color: black;
        display: inline-block;
        margin-bottom: 30px;
        position: relative;

        &::after {
            content: '';
            width: 100%;
            height: 5px;
            background-color: #ffdb25;
            position: absolute;
            left: 0;
            bottom: -25px;
        }
    }
}

.container_dt {
    padding: 0 25px 100px 25px;

    p {
        font-size: 26px;
        font-weight: 500;
        padding: 0;
        margin: 0;
        margin-bottom: 15px;
        line-height: 30px;
    }

    .poster {
        padding: 0;
        margin: 0;
        overflow: hidden;
        position: relative;

        img {
            width: 100%;
            position: relative;

            -webkit-transition: all 0.9s ease-in-out;
            -moz-transition: all 0.9s ease-in-out;
            -o-transition: all 0.9s ease-in-out;
            transition: all 0.9s ease-in-out;

            &:hover {
                -webkit-transform: scale(1);
                -ms-transform: scale(1);
                transform: scale(1);

                -webkit-transition: all 1.2s ease-in-out;
                -moz-transition: all 1.2s ease-in-out;
                -o-transition: all 1.2s ease-in-out;
                transition: all 1.2s ease-in-out;
            }
        }
    }

    .links {
        margin-top: 50px;
        padding: 23px 0 0 0;
        position: relative;

        &::before {
            content: '';
            width: 86px;
            height: 7px;
            background-color: black;
            position: absolute;
            top: 0;
            left: 0;
        }

        .btnlinks {
            font-size: 25px;
            display: inline-block;
            margin-right: 10px;
            color: black;

            &:hover {
                color: #4e4f63;
            }
        }

        .btnpdf {
            font-size: 18px;
            font-weight: normal;
            display: inline-block;
            margin-top: 20px;
            color: black;
            text-decoration: none;

            &:hover {
                color: #4e4f63;
            }
        }
    }
}

.dest_video {
    width: 100%;
    height: 660px;
    display: block;
    text-decoration: none;
    position: relative;
    padding: 0;
    margin: 0;
    overflow: hidden;

    .imgDest {
        position: absolute;
        width: 100%;
        height: 100%;
        background-position: center;
        background-size: cover;

        -webkit-transition: all 0.9s ease-in-out;
        -moz-transition: all 0.9s ease-in-out;
        -o-transition: all 0.9s ease-in-out;
        transition: all 0.9s ease-in-out;
    }

    &::before {
        content: '';
        width: 114px;
        height: 134px;
        position: absolute;
        top: 50%;
        left: 50%;
        margin-top: -67px;
        margin-left: -57px;
        background-image: url(../../assets/images/icon-play.png);
        z-index: 3;
        opacity: 0.7;

        -webkit-transition: all 1.5s ease-in-out;
        -moz-transition: all 1.5s ease-in-out;
        -o-transition: all 1.5s ease-in-out;
        transition: all 1.5s ease-in-out;
    }

    &:hover {
        .imgDest {
            -webkit-transform: scale(1.05);
            -ms-transform: scale(1.05);
            transform: scale(1.05);

            -webkit-transition: all 1.2s ease-in-out;
            -moz-transition: all 1.2s ease-in-out;
            -o-transition: all 1.2s ease-in-out;
            transition: all 1.2s ease-in-out;
        }

        &::before {
            opacity: 1;
        }
    }
}

.content_relacionados {
    border-top: 1px solid black;
    padding-top: 45px;
    margin-top: 50px;

    h3 {
        font-size: 30px;
        font-weight: bold;
        padding: 0;
        margin: 0 0 15px 0;
    }

    img {
        width: 100%;
        position: relative;
    }
}

.box_cards {
    padding: 0;
    margin: 0;
    display: block;
    text-decoration: none;
    position: relative;
    overflow: hidden;

    -webkit-transition: all 0.3s ease-in-out;
    -moz-transition: all 0.3s ease-in-out;
    -o-transition: all 0.3s ease-in-out;
    transition: all 0.3s ease-in-out;

    img {
        width: 100%;
        position: relative;
    }

    .title_filme {
        -ms-flex-align: center !important;
        align-items: center !important;
        display: flex;
        width: 100%;
        height: 100%;
        position: absolute;
        bottom: 0;
        left: 0;
        background-color: rgba(255, 223, 0, 0.9);
        text-align: center;
        line-height: 100%;
        overflow: hidden;
        z-index: 2;
        opacity: 0;

        -webkit-transition: all 0.3s ease-in-out;
        -moz-transition: all 0.3s ease-in-out;
        -o-transition: all 0.3s ease-in-out;
        transition: all 0.3s ease-in-out;

        h2 {
            font-size: 32px;
            font-weight: 500;
            color: black;
            display: block;
            text-align: center;
            width: 100%;
            position: relative;
            opacity: 0;

            -webkit-transition: all 0.9s ease-in-out;
            -moz-transition: all 0.9s ease-in-out;
            -o-transition: all 0.9s ease-in-out;
            transition: all 0.9s ease-in-out;
        }
    }

    &:hover {
        .title_filme {
            opacity: 1;

            h2 {
                -webkit-transform: scale(1.1);
                -ms-transform: scale(1.1);
                transform: scale(1.1);
                opacity: 1;

                -webkit-transition: all 1.2s ease-in-out;
                -moz-transition: all 1.2s ease-in-out;
                -o-transition: all 1.2s ease-in-out;
                transition: all 1.2s ease-in-out;
            }
        }
    }
}

.btn_back {
    height: 60px;
    line-height: 60px;
    padding: 0 40px 0 40px;
    background-color: black;
    color: white;
    text-transform: uppercase;
    font-size: 18px;
    font-weight: normal;
    text-decoration: none;
    margin-bottom: 30px;
    display: inline-block;
    position: relative;

    -webkit-transition: all 0.3s ease-in-out;
    -moz-transition: all 0.3s ease-in-out;
    -o-transition: all 0.3s ease-in-out;
    transition: all 0.3s ease-in-out;

    &:hover {
        background-color: #4e4f63;
    }
}

.btn_vermais {
    height: 60px;
    line-height: 60px;
    padding: 0 40px 0 60px;
    background-color: black;
    color: white;
    text-transform: uppercase;
    font-size: 18px;
    font-weight: normal;
    text-decoration: none;
    margin-top: 50px;
    display: inline-block;
    position: relative;

    .iconmais {
        position: absolute;
        font-size: 24px;
        left: 37px;
        top: 22px;
        width: 12px;
        height: 12px;
        line-height: 14px;


        -webkit-transition: all 0.3s ease-in-out;
        -moz-transition: all 0.3s ease-in-out;
        -o-transition: all 0.3s ease-in-out;
        transition: all 0.3s ease-in-out;
    }

    &:hover {
        color: white;
        text-decoration: none;
        background-color: #4e4f63;

        .iconmais {
            transform: rotate(270deg);

        }
    }
}

.img_video {
    position: relative;
}

.iframe_vd {
    width: 100%;
    max-height: 660px;
}

@media (max-width: 1600px) {
    .dest_video {
        height: 550px;
    }

    .iframe_vd {
        max-height: 550px;
    }
}

@media (max-width: 1440px) {
    .dest_video {
        height: 490px;
    }

    .header_content {
        h1 {
            font-size: 50px;

            a {
                top: -14px;
            }
        }
    }
}

@media (max-width: 1199px) {
    .dest_video {
        height: 380px;
    }

    .iframe_vd {
        max-height: 380px;
    }

    .header_content {
        h1 {
            font-size: 38px;

            a {
                font-size: 16px;
                width: 26px;
                height: 26px;
                line-height: 26px;
                top: -9px;
            }
        }

        .title_dt {
            font-size: 16px;
        }

        .name_dt {
            font-size: 20px;
            line-height: 24px;
        }

        .ano_dt {
            font-size: 20px;
            line-height: 24px;
        }
    }

    .container_dt {
        p {
            font-size: 20px;
            line-height: 24px;
        }
    }
}

@media (max-width:1023px) {
    .header_content {
        padding: 25px 0 0 0;
    }

    .container_dt {
        padding: 0 0 80px 0;
    }

    .content_relacionados {
        h3 {
            font-size: 20px;
        }
    }
}

@media (max-width:767px) {
    .dest_video {
        height: 310px;

        &::before {
            width: 34px;
            height: 40px;
            background-size: cover;
            margin-top: -20px;
            margin-left: -21px;
        }
    }

    .iframe_vd {
        max-height: 310px;
    }

    .header_content {
        h1 {
            font-size: 22px;

            a {
                font-size: 14px;
                width: 22px;
                height: 23px;
                line-height: 23px;
                top: -7px;
            }
        }

        .box_descri {
            padding: 30px 0 5px 0;
            margin: 0 0 30px 0;

            .title_dt {
                font-size: 14px;
            }

            .name_dt {
                font-size: 16px;
                line-height: 19px;
                margin-bottom: 15px;
            }

            .ano_dt {
                font-size: 16px;
                line-height: 20px;
            }
        }
    }

    .container_dt {
        .poster {
            margin: 0 0 15px 0;
        }

        p {
            font-size: 14px;
            line-height: 18px;
        }
    }

    .content_relacionados {
        padding-top: 25px;
        margin-top: 20px;

        h3 {
            font-size: 18px;
        }
    }

    .btn_vermais {
        font-size: 14px;
        height: 40px;
        line-height: 40px;

        .iconmais {
            top: 13px;
        }
    }

    .box_cards {
        margin-bottom: 15px;

        .title_filme {
            height: auto;
            opacity: 1;

            h2 {
                font-size: 18px;
                opacity: 1;
                padding: 11px 0 9px 0;
                margin: 0;
            }
        }
    }
}

@media (max-width:480px) {
    .header_content {
        h1 {
            font-size: 18px;

            a {
                top: -2px;
                margin-left: 10px;
            }
        }
    }

    .dest_video {
        height: 220px;
    }

    .iframe_vd {
        max-height: 220px;
    }
}