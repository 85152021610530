@charset "utf-8";
.content_dt {
    background-color: white;
    color: black;
    min-height: 45vh;
    padding: 0;

    .content_dtfilmes {
        padding: 60px 25px;
        height: 65vh;

        h1 {
            font-size: 30px;
            font-weight: bold;
            line-height: 1.2;
            padding: 0;
            margin: 0 0 35px 0;
            display: inline-block;
        }

        p {
            font-size: 28px;
            line-height: 1.2;
            font-weight: 500;
            letter-spacing: 0.01rem;
            padding: 0;
            margin: 0 0 28px 0;
            display: inline-block;
        }
        .wrapper {
            max-width: 1920px;
            width: 100%;
            margin: 0 auto 0;
        }
    }
}
