@charset "utf-8";

.content_dt {
    padding: 30px 15px 100px 15px;
    background-color: white;
    color: black;
    overflow: hidden;

    img {
        width: 100%;
    }
}

.noth1 {
    font-size: 0 !important;
    color: transparent !important;
    margin-bottom: 0 !important;
}

.gridflilmes {
    background-color: white;
    padding: 0;
    overflow: hidden;
    color: black;

    .gridum {
        padding: 7px;

        ul {
            list-style: none;
            padding: 0;
            margin: 0;
            display: block;
            height: auto !important;
            overflow: hidden;

            li {
                width: 33.33%;
                height: 546px;
                overflow: hidden;
                padding: 7px;
                position: relative !important;
                -webkit-animation-name: fadeInUp;
                animation-name: fadeInUp;
                display: block;
                top: 0 !important;
                left: 0 !important;
                float: left;

                img {
                    width: 100%;
                }

                .box_cards {
                    width: 100%;
                    height: 100%;
                    padding: 0;
                    margin: 0;
                    display: block;
                    text-decoration: none;
                    position: relative;
                    overflow: hidden;
                    background-size: cover;
                    background-position: center;
                    background-color: rgba(255, 223, 0, 0.95);

                    -webkit-transition: all 0.3s ease-in-out;
                    -moz-transition: all 0.3s ease-in-out;
                    -o-transition: all 0.3s ease-in-out;
                    transition: all 0.3s ease-in-out;

                    .title_filme {
                        -ms-flex-align: center !important;
                        align-items: center !important;
                        display: flex;
                        width: 100%;
                        height: 100%;
                        position: absolute;
                        bottom: 0;
                        left: 0;
                        background-color: rgba(255, 223, 0, 0.5);
                        text-align: center;
                        line-height: 100%;
                        overflow: hidden;
                        opacity: 0;

                        -webkit-transition: all 0.3s ease-in-out;
                        -moz-transition: all 0.3s ease-in-out;
                        -o-transition: all 0.3s ease-in-out;
                        transition: all 0.3s ease-in-out;

                        h2 {
                            font-size: 28px;
                            font-weight: 500;
                            color: black;
                            display: block;
                            text-align: center;
                            width: 100%;
                            position: relative;
                            opacity: 0;

                            -webkit-transition: all 0.9s ease-in-out;
                            -moz-transition: all 0.9s ease-in-out;
                            -o-transition: all 0.9s ease-in-out;
                            transition: all 0.9s ease-in-out;
                        }
                    }

                    &:hover {
                        .title_filme {
                            opacity: 1;

                            h2 {
                                opacity: 1;
                                -webkit-transform: scale(1.1);
                                -ms-transform: scale(1.1);
                                transform: scale(1.1);

                                -webkit-transition: all 0.9s ease-in-out;
                                -moz-transition: all 0.9s ease-in-out;
                                -o-transition: all 0.9s ease-in-out;
                                transition: all 0.9s ease-in-out;
                            }
                        }
                    }
                }
            }
        }
    }

    .griddois {
        padding: 40px 15px;

        ul {
            list-style: none;
            padding: 0;
            margin: 0;
            display: grid;
            grid-template-columns: 35% 35% 35%;

            li {
                width: 90%;
                padding: 0;

                a {
                    font-size: 26px;
                    font-weight: bold;
                    color: black;
                    text-decoration: none;
                    position: relative;

                    &:hover {
                        &::after {
                            content: '';
                            width: 100%;
                            height: 1px;
                            background-color: black;
                            position: absolute;
                            left: 0;
                            bottom: 3px;
                        }
                    }
                }

                .ano {
                    font-size: 20px;
                    font-weight: normal;
                }
            }
        }
    }
}

.btn_vermais {
    height: 50px;
    line-height: 50px;
    padding: 0 40px 0 60px;
    background-color: black;
    color: white;
    text-transform: uppercase;
    font-size: 12px;
    font-weight: normal;
    text-decoration: none;
    margin-top: 30px;
    display: inline-block;
    position: relative;

    .iconmais {
        position: relative;
        font-size: 18px;
        //left: 37px;
        //top: -3px;
        // width: 12px;
        // height: 12px;
        // line-height: 14px;

        -webkit-transition: all 0.3s ease-in-out;
        -moz-transition: all 0.3s ease-in-out;
        -o-transition: all 0.3s ease-in-out;
        transition: all 0.3s ease-in-out;
    }

    &:hover {
        color: white;
        text-decoration: none;
        background-color: #333;

        .iconmais {
            /* left: 40px;
            top: 0px;
            transform: rotate(50deg); */
            -webkit-transition: all 0.3s ease-in-out;
            -moz-transition: all 0.3s ease-in-out;
            -o-transition: all 0.3s ease-in-out;
            transition: all 0.3s ease-in-out;
        }
    }
}

.img_mobile {
    display: none;
}

@-webkit-keyframes fadeInUp {
    0% {
        opacity: 0;
        -webkit-transform: translate3d(0, 100%, 0);
        transform: translate3d(0, 100%, 0);
    }

    100% {
        opacity: 1;
        -webkit-transform: none;
        transform: none;
    }
}

@keyframes fadeInUp {
    0% {
        opacity: 0;
        -webkit-transform: translate3d(0, 100%, 0);
        transform: translate3d(0, 100%, 0);
    }

    100% {
        opacity: 1;
        -webkit-transform: none;
        transform: none;
    }
}

.fadeInUp {
    -webkit-animation-name: fadeInUp;
    animation-name: fadeInUp;
}

@media (max-width: 1500px) {
    .gridflilmes {
        .gridum {
            ul {
                li {
                    height: 700px;

                    .box_cards {
                        .title_filme {
                            h2 {
                                font-size: 26px;
                            }
                        }
                    }
                }
            }
        }

        .griddois {
            ul {
                li {
                    a {
                        font-size: 22px;
                    }

                    .ano {
                        font-size: 16px;
                    }
                }
            }
        }
    }
}

@media (max-width:1366px) {
    .gridflilmes {
        .gridum {
            ul {
                li {
                    height: 560px;

                    .box_cards {
                        .title_filme {
                            h2 {
                                font-size: 19px;
                            }
                        }
                    }
                }
            }
        }

        .griddois {
            ul {
                li {
                    a {
                        font-size: 20px;
                    }
                }
            }
        }
    }
}


@media (max-width:1050px) {
    .gridflilmes {
        .gridum {
            ul {
                li {
                    height: 470px;

                    .box_cards {
                        .title_filme {
                            h2 {
                                font-size: 18px;
                            }
                        }
                    }
                }
            }
        }

        .griddois {
            ul {
                li {
                    a {
                        font-size: 18px;
                    }
                }
            }
        }
    }
}

@media (max-width:1023px) {
    .gridflilmes {
        padding: 15px 0 15px 0;

        .gridum {
            .box_cards {
                .title_filme {
                    opacity: 1 !important;
                    height: auto !important;
                    padding: 15px !important;

                    h2 {
                        opacity: 1 !important;
                        margin: 0;
                    }
                }
            }
        }
    }
}


@media (max-width:990px) {
    .gridflilmes {
        .gridum {
            ul {
                li {
                    width: 50%;
                    height: 470px;
                }
            }
        }
    }
}

@media (max-width:767px) {
    .gridflilmes {
        .gridum {
            ul {
                height: auto !important;

                li {
                    position: relative !important;
                    width: 100% !important;
                    height: auto !important;
                    top: 0 !important;
                    left: 0 !important;

                    .box_cards {
                        background: transparent !important;
                    }
                }
            }
        }

        .griddois {
            ul {
                display: block;
            }
        }
    }

    .btn_vermais {
        height: 30px;
        line-height: 30px;
        padding: 0px 40px;
        background-color: black;
        color: white;
        text-transform: uppercase;
        font-size: 8px;
        font-weight: normal;
        text-decoration: none;
        margin-top: 30px;
        display: inline-block;
        position: relative;

        .iconmais {
            position: relative;
            font-size: 12px;
        }
    }

    .img_mobile {
        display: block;
        width: 100%;
        height: auto;
    }
}