@charset "utf-8";

.menu-link-active {
  color: #ffdb25 !important;
}

.menu-link-active::after {
  content: '';
  width: 100% !important;
}

header .btn.btn-primary,
.Header_mobile_menu__2m8LN .btn.btn-primary {
  background-color: transparent !important;
  border: 0;
  padding: 0;

  font-size: 18px;
  color: white;
  text-decoration: none;
  position: relative;
  text-transform: uppercase;
}

header .btn.btn-primary:hover,
.Header_mobile_menu__2m8LN .btn.btn-primary:hover {
  color: #ffdb25;
}

header .btn.btn-primary:focus,
.Header_mobile_menu__2m8LN .btn.btn-primary:focus {
  outline: none;
  box-shadow: none;
}

/* MENU MOBILE */
/* Position and sizing of burger button */

.btn.btn-primary {
  background-color: transparent !important;
  border: 0;
  padding: 0;
  font-size: 18px;
  color: #fff;
  text-decoration: none;
  position: relative;
  text-transform: uppercase;
}

.bm-burger-button {
  position: absolute;
  width: 26px;
  height: 20px;
  right: 15px;
  top: 25px;
  z-index: 1500 !important;
}

.bm-burger-button button:focus {
  outline: none !important;
  box-shadow: none !important;
}

.bm-burger-button .bm-burger-bars {
  -webkit-transition: all 0.3s ease-in-out;
  -moz-transition: all 0.3s ease-in-out;
  -o-transition: all 0.3s ease-in-out;
  transition: all 0.3s ease-in-out;

  opacity: 1 !important;
}

.menu_active .bm-burger-button .bm-burger-bars:nth-child(1) {
  transform: rotate(45deg);
  top: 8px !important;
}

.menu_active .bm-burger-button .bm-burger-bars:nth-child(2) {
  opacity: 0 !important;
  left: -50px !important;
}

.menu_active .bm-burger-button .bm-burger-bars:nth-child(3) {
  transform: rotate(-45deg);
  top: 8px !important;
}



/* Color/shape of burger icon bars */
.bm-burger-bars {
  background: #ffdb25 !important;
  height: 2px !important;
}

/* Color/shape of burger icon bars on hover*/
.bm-burger-bars-hover {
  background: #ffdb25 !important;
  opacity: 1;
}

/* Position and sizing of clickable cross button */
.bm-cross-button {
  display: none !important;
  height: 26px !important;
  width: 20px !important;
  right: 15px !important;
  background: #ffdb25 !important;
}

/* Color/shape of close button cross */
.bm-cross {
  background: #ffdb25 !important;
}

/*
Sidebar wrapper styles
Note: Beware of modifying this element as it can break the animations - you should not need to touch it in most cases
*/
.bm-menu-wrap {
  position: fixed;
  width: 100% !important;
  height: 100%;
  top: 0;
  background-color: rgba(0, 0, 0, 0.9);

  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  -ms-flex-align: center !important;
  align-items: center !important;
}

/* General sidebar styles */
.bm-menu {
  width: 100% !important;
  height: auto;
  background-color: transparent;
  padding: 30px 10px 0;
  font-size: 1.15em;
  height: auto !important;
  top: 10px;
}

/* Morph shape necessary with bubble or elastic */
.bm-morph-shape {
  width: 100% !important;
  /* fill: black; */
  display: none;
}

/* Wrapper for item list */
.bm-item-list {
  left: 0 !important;
  width: 100% !important;
  color: #b8b7ad;
  padding: 0.8em;
}

/* Individual item */
.bm-item {
  display: inline-block;
}

/* Styling of overlay */
.bm-overlay {
  background: transparent !important;
}

.bm-item-list ul:focus {
  outline: none;
  box-shadow: 0;
}


.Header_buscali__2RqI3 .btn.btn-primary::after {
  display: none !important;
}

.Header_buscali_d__3KIBS .Header_search_dt2__2youK .btn.btn-primary::after {
  display: none !important;
}


@media (max-width:1366px) {
  header .btn.btn-primary {
    font-size: 20px !important;
  }

  .btn.btn-primary {
    font-size: 26px !important;
  }
}

@media (max-width:1023px) {
  .btn.btn-primary {
    font-size: 16px;
  }
}