@charset "utf-8";
.content_dt {
    background-color: white;
    color: black;
    min-height: 45vh;
    padding: 0;

    .content_dtfilmes {
        padding: 60px 25px;
        height: 65vh;

        h1 {
            font-size: 30px;
            font-weight: bold;
            line-height: 1.2;
            padding: 0;
            margin: 0 0 35px 0;
            display: inline-block;
        }

        p {
            font-size: 28px;
            line-height: 1.2;
            font-weight: 500;
            letter-spacing: 0.01rem;
            padding: 0;
            margin: 0 0 28px 0;
            display: inline-block;
        }
        .wrapper {
            max-width: 1920px;
            width: 100%;
            margin: 0 auto 0;
        }
    }

    ul {
        list-style: none;
        padding: 0;
        margin: 0;
        li {
            padding: 0 0 10px 0;
            a {
                font-size: 18px;
                font-weight: bold;
                line-height: 20px;
                color: black;
                position: relative;
                display: inline-block;

                &::after {
                    content: '';
                    width: 0;
                    height: 1px;
                    background-color: black;
                    position: absolute;
                    left: 0;
                    bottom: 2px;

                    -webkit-transition: all 0.3s ease-in-out;
                    -moz-transition: all 0.3s ease-in-out;
                    -o-transition: all 0.3s ease-in-out;
                    transition: all 0.3s ease-in-out;
                }

                &:hover {
                    text-decoration: none;
                    &::after {
                        width: 100%;
                    }
                }
            }
        }
    }
}

@media (max-width:767px) {
    .content_dtfilmes {
        h1 {
            font-size: 20px;
        }
    }
    .content_dt {
        ul {
            li {
                a {
                    font-size: 16px;
                    line-break: 18px;
                }
            }
        }
    }
}
