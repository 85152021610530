@charset "utf-8";

.content_pd {
    padding: 0 25px;
}

.header {
    width: 100%;
    background-color: black;
    padding: 25px 0;
    position: absolute;
    z-index: 2;
    top: 0;
    left: 0;

    .logo_app {
        display: inline-block;
        padding: 0;
        margin: 0;
    }

    ul {
        color: white;
        padding: 0 0;
        margin: 0;
        text-align: right;
        overflow: hidden;
        position: relative;
        top: 8px;

        li {
            display: inline-block;
            color: #fff;
            padding: 0 16px;
            font-size: 18px;
            text-transform: uppercase;

            .idioma {
                font-size: 14px;
                border: 1px solid white;
                padding: 4px 6px 3px 6px;
                position: relative;
                bottom: 3px;
                cursor: pointer;

                &::after {
                    display: none;
                }

                &:hover {
                    background-color: #ffdb25;
                    border-color: #ffdb25;
                    color: black;
                }
            }

            a {
                font-size: 18px;
                color: white;
                text-decoration: none;
                position: relative;

                &:hover {
                    color: #ffdb25;
                }

                &.iconsearch {
                    img {
                        position: relative;
                        top: 2px;
                    }
                }
            }

            &:first-child {
                padding: 0 16px 0 0;
            }

            &:last-child {
                padding: 0 0 0 16px;

                a {
                    &::after {
                        display: none;
                    }
                }
            }

            &.guara {
                a {
                    position: relative;
                    display: block;
                    bottom: 6px;

                    &::after {
                        bottom: -4px;
                    }

                    img {
                        width: 62px;
                    }
                }
            }

            &.assine {
                
                .btn {
                    background-color: transparent !important;
                }
            }
        }
    }
}

.search_header {
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    border-radius: 0;
    border: 0;
    background-color: #424040 !important;
    width: 100%;
    color: white !important;
    font-size: 20px;
    padding: 0 30px 0 10px;

    &:focus {
        color: white !important;
        outline: none;
        box-shadow: none;
        background-color: #424040;
    }

    &::placeholder {
        color: white !important;
        background-color: #424040;
    }
}

.search_header_d {
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    border-radius: 0;
    border: 0;
    background-color: #424040 !important;
    width: 100%;
    color: white !important;
    font-size: 20px;
    padding: 0 30px 0 10px;

    &:focus {
        color: white !important;
        outline: none !important;
        box-shadow: none !important;
        background-color: #424040 !important;
    }

    &::placeholder {
        color: white !important;
        background-color: #424040;
    }
}

.content_dt {
    width: 100%;
    margin: 0 auto 0;
}

.buscali {
    position: relative;
    padding: 0 0 10px 0;
    margin-top: 11px;

    button {
        position: absolute !important;
        top: 2px;
        right: 8px;
    }
}

.buscali_d {
    position: relative;
    padding: 0 0 0 36px !important;

    button {
        position: absolute !important;
        top: 7px;
        right: 8px;
    }

    .search_dt2 {
        position: relative;
        width: 0;

        input {
            -webkit-appearance: none;

            &::-webkit-search-cancel-button {
                display: none;
            }
        }

        -webkit-transition: all 1s ease-in-out;
        -moz-transition: all 1s ease-in-out;
        -o-transition: all 1s ease-in-out;
        transition: all 1s ease-in-out;

        .search_header_d {
            padding: 0 !important;
        }
    }

    &:hover {
        .search_dt2 {
            width: 300px;

            .search_header_d {
                padding: 0 30px 0 10px !important;
            }
        }
    }
}

.menumobile_nv {
    padding: 0
}

.mobile_menu {
    display: none;
    
    ul {
        list-style: none;
        padding: 0;
        margin: 0;

        li {
            padding: 0 0 15px 0;

            .idioma {
                font-size: 14px;
                border: 1px solid white;
                padding: 4px 6px 3px 6px;
                position: relative;
                bottom: 3px;

                &::after {
                    display: none;
                }

                &:hover {
                    background-color: #ffdb25;
                    border-color: #ffdb25;
                    color: black;
                }
            }

            a {
                font-size: 18px;
                color: white;
                text-decoration: none;
                position: relative;
                text-transform: uppercase;

                &:hover {
                    color: #ffdb25;
                }

                &.menu-link-active {
                    color: #ffdb25;
                }

                &.iconsearch {
                    img {
                        position: relative;
                        top: 2px;
                    }
                }
            }

            &.guara {
                padding: 8px 0 10px 0;

                a {
                    position: relative;
                    display: inline-block;
                    bottom: 6px;

                    img {
                        width: 60px;
                    }
                }
            }

            &.assine {
                .btn {
                    background-color: transparent !important;
                }
            }
        }
    }
}

@media (max-width:1920px) {
    .content-dt {
        max-width: 100%;
        ;
    }
}

@media (max-width:1500px) {
    .buscali_d {
        &:hover {
            .search_dt2 {
                width: 180px;
                ;
            }
        }
    }
}

@media (max-width:1366px) {
    .header {
        // height: 10.2vh;
        padding: 20px 0;

        .logo_app {
            img {
                width: 220px;
                height: 50px;
            }
        }

        ul {
            .assine button {
                font-size: 16px !important;
            }

            li {
                font-size: 16px;

                a {
                    font-size: 16px;
                }

                &.guara {
                    a {
                        bottom: 4px;

                        img {
                            max-width: 60px;
                        }
                    }
                }
            }
        }
    }
}

@media (max-width:1023px) {
    .m_desktop {
        display: none;
    }

    .mobile_menu {
        display: block;
    }

    .header {
        // height: 7.3vh;
        padding: 15px 0;

        .logo_app {
            img {
                width: 180px;
                height: 41px;
            }
        }
    }

    .content_pd {
        padding: 0;
    }

}