.classification_L {
    font-size: 18px;
    font-weight: 700;
    color: #fff;
    border-radius: 5px;
    display: inline-block;
    margin-left: 14px;
    padding: 1px 0 0;
    position: relative;
    top: -29px;
    text-decoration: none !important;
    width: 30px;
    height: 30px;
    line-height: 30px;
    text-align: center;
    background-color: #00af52;
}
.classification_10 {
    font-size: 18px;
    font-weight: 700;
    color: #fff;
    border-radius: 5px;
    display: inline-block;
    margin-left: 14px;
    padding: 1px 0 0;
    position: relative;
    top: -29px;
    text-decoration: none !important;
    width: 30px;
    height: 30px;
    line-height: 30px;
    text-align: center;
    background-color: #019ee1;
}
.classification_12 {
    font-size: 18px;
    font-weight: 700;
    color: #fff;
    border-radius: 5px;
    display: inline-block;
    margin-left: 14px;
    padding: 1px 0 0;
    position: relative;
    top: -29px;
    text-decoration: none !important;
    width: 30px;
    height: 30px;
    line-height: 30px;
    text-align: center;
    background-color: #ffcb01;
}
.classification_14 {
    font-size: 18px;
    font-weight: 700;
    color: #fff;
    border-radius: 5px;
    display: inline-block;
    margin-left: 14px;
    padding: 1px 0 0;
    position: relative;
    top: -29px;
    text-decoration: none !important;
    width: 30px;
    height: 30px;
    line-height: 30px;
    text-align: center;
    background-color: #ff6702;
}
.classification_16 {
    font-size: 18px;
    font-weight: 700;
    color: #fff;
    border-radius: 5px;
    display: inline-block;
    margin-left: 14px;
    padding: 1px 0 0;
    position: relative;
    top: -29px;
    text-decoration: none !important;
    width: 30px;
    height: 30px;
    line-height: 30px;
    text-align: center;
    background-color: #fe0002;
}
.classification_18 {
    font-size: 18px;
    font-weight: 700;
    color: #fff;
    border-radius: 5px;
    display: inline-block;
    margin-left: 14px;
    padding: 1px 0 0;
    position: relative;
    top: -29px;
    text-decoration: none !important;
    width: 30px;
    height: 30px;
    line-height: 30px;
    text-align: center;
    background-color: #000000;
}
.classification_L:hover {
    color: #fff;
    background-color: #0c8d48;
}
.classification_10:hover {
    color: #fff;
    background-color: #0b7dad;
}
.classification_12:hover {
    color: #fff;
    background-color: #c7a20e;
}
.classification_14:hover {
    color: #fff;
    background-color: #e0630f;
}
.classification_16:hover {
    color: #fff;
    background-color: #db0f0f;
}
.classification_18:hover {
    color: #fff;
    background-color: #161616;
}
