@charset "utf-8";

.content_pd {
    padding: 0 30px;
}

.content_home {
    width: 100%;
    height: auto;
    padding: 0;
    top: 0;
    z-index: 1;

    img {
        width: 100% !important;
        height: auto !important;
        position: relative;
        z-index: 1;
    }
}

.noth1 {
    font-size: 0 !important;
    color: transparent !important;
    margin-bottom: 0 !important;
}

.destexto {
    display: inline-block;
    background-color: white;
    padding: 15px 22px 10px 22px;
    margin: 0 0 65px 0;
    font-weight: bold;
    font-size: 80px;
    line-height: 93px;
    color: black;
    position: relative;

    &::before {
        content: '';
        width: 100px;
        height: 7px;
        background-color: black;
        position: absolute;
        top: -27px;
        left: 0;
    }

    h2 {
        font-size: 90px;
        padding: 0;
        margin: 0;
    }
}

.box_content {
    width: 100%;
    margin: 0 auto 0;
    padding: 0 0;
}

.box_caption {
    left: 40px !important;
    right: 40px !important;
    text-align: left;
}

.box_text {
    width: auto;
    display: inline-block;
    background-color: white;
    padding: 8px 40px 0 22px;
    margin: 0 0 5px 0;
    position: relative;
    z-index: 2;
    line-height: 70px;

    h3 {
        font-weight: 1000;
        font-size: 80px;
        color: #000;
        display: inline;
        padding: 0;
        margin: 0;
        letter-spacing: 0;
        line-height: 1.2;
    }

    &::before {
        content: '';
        width: 100px;
        height: 5px;
        background-color: #ffdb25;
        position: absolute;
        top: -27px;
        left: 0;
    }

}

.box_carousel {
    display: block;
    position: relative;
    padding: 0;
    height: 88.7vh;
    background-size: cover;
    background-position: 50%;
}

@media (max-width: 1920px) {
    max-width: 100%;
}

@media (max-width:1600px) {
    .box_text {
        line-height: 66px;

        h3 {
            font-size: 66px;
        }
    }
}

@media (max-width:1440px) {
    .box_text {
        line-height: 58px;

        h3 {
            font-size: 58px;
        }
    }
}

@media (max-width:1366px) {
    .box_text {
        padding: 8px 25px 2px 15px;
        line-height: 52px;

        h3 {
            font-size: 52px;
        }

        h3 a {
            top: -16px;
        }
    }

    .box_carousel {
        height: 91.8vh;
    }
}

@media (max-width:1280px) {
    .box_text {
        line-height: 48px;

        h3 {
            font-size: 48px;
        }

        h3 a {
            font-size: 16px;
            width: 26px;
            height: 26px;
            line-height: 26px;
        }

        h3 a:hover {
            top: -16px;
            font-size: 16px;
            width: 26px;
            height: 26px;
            line-height: 26px;
        }
    }
}

@media (max-width:1023px) {
    .box_caption {
        left: 15px !important;
        right: 15px !important;
    }

    .box_text {
        line-height: 40px;

        a {
            font-size: 14px !important;
            width: 24px !important;
            height: 24px !important;
            line-height: 24px !important;
            top: -5px !important;
        }

        h3 {
            font-size: 28px;
        }

        &::before {
            width: 80px;
            height: 3px;
            top: -15px;
        }
    }

}

@media (max-width:800px) {
    .box_carousel {
        height: 88vh;
    }

    .box_caption {
        padding-bottom: 0;
        bottom: 80px;
    }
}

@media (max-width:767px) {
    .box_text {
        line-height: 32px;

        h3 {
            font-size: 20px;
        }
    }
}