@charset "utf-8";
html {
    height: 100%;
}

body {
    min-height: 100%;
    padding: 108px 0 0 0;
    margin: 0;
    font-family: 'Gilroy' !important;
    font-weight: 400;
    color: black !important;
    position: relative;
    letter-spacing: 0.04rem;
    -webkit-hyphens: none;
    -ms-hyphens: none;
    hyphens: none;
    text-rendering: optimizeSpeed;
    font-variant-ligatures: none;
    no-common-ligatures: none;
}

*::-webkit-search-cancel-button {
    -webkit-appearance: searchfield-cancel-button;
}

@media (max-width:1366px) {
    body {
        padding: 98px 0 0 0;
    }
}

@media (max-width:1023px) {
    body {
        padding: 68px 0 0 0;
    }
}